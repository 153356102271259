@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");

/* ho */
* {
  -webkit-user-drag: none;
}
/* ho */
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  scroll-behavior: smooth;
  color: #ffd700;
}

body {
  background: #313131;
}

input[name="password"]:focus {
  box-shadow: none !important;
}

#banner_image {
  height: 23rem;
  object-fit: cover;
}

@media (max-width: 768px) {
  #banner_image {
    height: 18rem;
    object-fit: fill;
  }
}

@media (max-width: 500px) {
  #banner_image {
    height: 12rem;
    object-fit: fill;
  }
}

/* football hover */
.football {
  transition: 0.7s ease;
}

.football:hover {
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
  transition: 0.7s ease;
}

.full-background {
  /* Center the content */
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* Take full size */
  height: 100vh;
  width: 100%;

  /* Background */
  background: url("https://img.freepik.com/premium-photo/3d-rendering-online-gambling_99433-4597.jpg?w=2000")
    center;
}

/* card slider start */
html,
body {
  position: relative;
  height: 100%;
}

body {
  background-image: linear-gradient(to bottom, #000000c5, #000000f1),
    url("https://png.pngtree.com/thumb_back/fw800/background/20230717/pngtree-d-render-of-futuristic-roulette-wheel-and-slot-machine-with-neon-image_3891435.jpg");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

.swiper {
  width: 100%;
  height: auto;
}

.swiper-slide {
  text-align: center;
  font-size: 15.5px;
  padding-top: 3px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* card slider end */

/* card image hover */
.group {
  position: relative;
}

.image {
  opacity: 1;
  background-color: goldenrod;
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  opacity: 0;
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 42%;
  transform: translate(-40%, -40%);
  -ms-transform: translate(-30%, -30%);
}

.group:hover .image {
  opacity: 0.3;
}

.group:hover .middle {
  opacity: 1;
}

.text {
  background-color: transparent;
  color: rgb(223, 214, 214);
  font-size: 10px;
  font-weight: bolder;
  /* padding: 8px 0px; */
  /* padding: 16px 32px; */
  border-radius: 10%;
  transition: 0.5s;
  cursor: pointer;
  width: 80px;
  border: 3px solid goldenrod;
}

.text:hover {
  /* background: rgb(240, 232, 181); */
  color: black;
  font-weight: bolder;
  /* font-weight: 900; */
  background-color: gold;
}

.image {
  background-color: black;
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: end;
}

.group:hover .image {
  opacity: 0.6;
}

.group:hover .middle {
  opacity: 1;
}

.group img {
  transition: 0.5s;
}

.group img:hover {
  opacity: 0.8;
}

.text {
  background-color: transparent;
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: bolder;
  /* padding: 16px 32px; */
  /* margin-right: -5px; */
  transition: 0.5s;
  border-radius: 10%;
  cursor: pointer;
  width: 130px;
  height: 50px;
  padding: auto;
}

.text:hover {
  background: rgb(240, 232, 181);
  color: black;
  font-weight: bold;
}

/* scroll bar start */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(5, 5, 5);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(0, 0, 0);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(3, 67, 97);
}

/* scroll bar end */

/* search btn */
.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
}

.input-search {
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color: goldenrod;
}

.input-search::placeholder {
  color: goldenrod;
  font-size: 15px;
  letter-spacing: 2px;
  font-weight: 100;
}

.btn-search {
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: goldenrod;
  background-color: transparent;
  pointer-events: painted;
}

.btn-search:focus ~ .input-search {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

.input-search:focus {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid goldenrod;
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

.countBtn {
  background-color: white;
  color: black;
  cursor: pointer;
  transition: 0.3s;
}

.countBtn:hover {
  background-color: rgb(212, 186, 121);
  color: rgb(9, 9, 9);
}

.activeCountBtn {
  background-color: rgb(212, 186, 121);
  color: rgb(9, 9, 9);
}

.categoryBtn {
  background-color: #313131;
  transition: 0.3s;
}

.categoryBtn:hover {
  background-color: rgb(171, 156, 115);
  color: #0d0d0d;
}

/* text animation infinte */
.text-warper {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
}

.text-warper span {
  white-space: nowrap;
  animation: move-rtl 20000ms linear infinite;
}

@keyframes move-rtl {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.profileBtn {
  padding-left: 15px !important;
}

.profileBtn:hover {
  border-radius: 5px;
  background-color: rgb(153, 71, 230);
  color: white;
}

.cash {
  opacity: 0.8;
  cursor: pointer;
  transition: 0.5s;
}

.cash:hover {
  opacity: 1.1;
  transform: scale(1.1);
}

.popup .overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9998;
  display: none;
}

.popup .content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  background: rgb(42, 41, 41);
  /* width: 200px; */
  height: auto;
  z-index: 9998;
  padding: 20px;
  box-sizing: border-box;
  color: goldenrod;
}

.popup .close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  background: #222;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
}

.popup.active .overlay {
  display: block;
}

.popup.active .content {
  transition: all 300ms ease-in-out;
  transform: translate(-50%, -50%) scale(1);
}

.countDigits {
  transition: 0.3s;
}

.countDigits:hover {
  border: 1px solid goldenrod;
}

.payAccount {
  transition: 0.3s;
  background-color: white;
}

.payAccount:hover {
  background-color: goldenrod;
}

.payAccountActive {
  background-color: goldenrod;
}

.gamehover {
  opacity: 1;
  transition: 0.5s;
}

.gamehover:hover {
  opacity: 0.3;
}

.bs-no-white-space {
  white-space: nowrap;
}

.all-games-name {
  color: goldenrod;
  text-align: center;
  font-size: 15.5px;
  padding-top: 3px;
  width: 200px;
}

.game-provider-active {
  background-color: rgb(171, 156, 115);
  color: #0d0d0d;
}

.blogImage {
  opacity: 1;
  transition: 0.5s;
  cursor: pointer;
}

.blogImage:hover {
  opacity: 0.5;
}

/* blog */
#item-blog p:has(iframe) {
  width: 100% !important;
  height: 100% !important;
}

#item-blog iframe {
  width: 100% !important;
  height: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.animate_lottery_5s {
  --animate-duration: 5s;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.bg_goldenrod {
  background-color: rgb(0, 0, 0);
}

.border-goldenrod {
  border-color: goldenrod !important;
}

.swiper-button-prev {
  color: gold !important;
  width: 5px !important;
  height: 50px !important;
}

.swiper-button-next {
  color: gold !important;
  width: 5px !important;
  height: 50px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-green {
  border-color: #14fc65 !important;
}

.border-orange {
  border-color: #fd6f09 !important;
}

.border-red {
  border-color: #ff1616 !important;
}

.border-gold {
  border-color: goldenrod !important;
}

.bs-flex {
  flex: 1;
}

.fixed-at-side {
  width: 5%;
  position: fixed;
  top: 75%;
  transform: translate(0%, -70%);
  z-index: 999;
  right: -0.5%;
  cursor: pointer;
  transition: all 0.6s ease-in-out;
}

@keyframes animate {
  to {
    transform: translate(0%, -45%) scale(1.03);
  }
}
.text-goldenrod {
  color: goldenrod;
}

.back-btn {
  position: absolute;
  right: 55px;
  top: 20px;
  width: 30px;
  height: 30px;
  background: #222;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
}

.banner {
  display: block;
}

.game-section {
  width: 200px;
}

.game-section-image {
  height: 160px;
}

.cashin-frame {
  background-color: #9cabb9;
  border: 1px solid rgb(1, 255, 234);
}

.betSection {
  width: 300px;
}

.font-responsive {
  font-size: 14px;
}

.swiper-pagination-bullet {
  background-color: #fff !important;
}

.swiper-pagination-bullet-active {
  background-color: rgb(250, 204, 21) !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgb(1, 6, 36);
  border-radius: 10rem;
}

.frame-container {
  border: 4px solid #e2a20188;
  border-radius: 15px;
}

.bet-info {
  width: 300px;
}

.diagram {
  width: 380px;
}

.bet-history {
  width: 350px;
}

.bet-history-three {
  width: 400px;
}

.bet-action {
  width: 400px;
}

.three-box {
  width: 800px;
}

@media screen and (max-width: 640px) {
  .swiper-container {
    width: 640px;
  }

  .game-section {
    width: 110px;
  }

  .game-section-image {
    height: 120px;
  }

  .all-games-name {
    color: goldenrod;
    text-align: center;
    font-size: 10px;
    padding-top: 3px;
    width: 100px;
  }

  .section-games-name {
    color: goldenrod;
    text-align: center;
    font-size: 10px;
    padding-top: 3px;
    width: 100px;
  }

  .text {
    width: 100px;
  }

  .frame-container {
    border: none;
    border-radius: 0px;
  }

  .cashin-frame {
    background-color: transparent;
    border: none;
  }

  .bet-info {
    width: 100%;
  }

  .diagram {
    width: 100%;
  }

  .bet-history {
    width: 100%;
  }

  .bet-history-three {
    width: 100%;
  }

  .bet-action {
    width: 100%;
  }
}

@media screen and (max-width: 440px) {
  .swiper-container {
    width: 440px;
  }

  .text {
    width: 80px;
  }

  .input-style {
    width: 35px;
  }
}

@media screen and (max-width: 580px) {
  .swiper-container {
    width: 580px;
  }
}

@media screen and (max-width: 868px) {
  .swiper-container {
    width: 768px;
  }
}

.mobile-side-bar > .active {
  background-color: #313131 !important;
  color: goldenrod !important;
}

.btn-light-gold {
  background-color: #313131 !important;
}

.game-preloader {
  position: fixed;
  padding: 0;
  margin: 0;
  z-index: 1000;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background: #0d0d0d;
}

.game-frame {
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.theme-color {
  background-color: #121313;
}

.text-color-main {
  color: #e2a101;
}

.main-border-color {
  border-color: #e2a101;
  border-width: 4px;
}

.main-bg {
  background-color: transparent;
  color: #e2a101;
}

.main-bg:hover {
  color: rgb(255, 230, 0);
}

.navbar-color {
  background-color: #090909;
}

.active-bg-color {
  background-color: #471712;
  color: rgb(255, 230, 0);
}
.active-bg-color:hover {
  color: red;
}

.cat_img {
  width: 93%;
}
.cat_img:hover {
  transform: scale(1.05);
}
.mrgTop {
  margin-top: 8.5rem;
}
.catproviderDiv {
  width: 21%;
  height: 12rem;
}

@media (max-width: 762px) {
  .cat_img {
    width: 100%;
  }
  .mrgTop {
    margin-top: 6.5rem;
  }
  .catproviderDiv {
    width: 75%;
    height: 12rem;
  }
  .fixed-at-side {
    width: 18%;
    left: 0;
    position: fixed;
    top: 98%;
    z-index: 999;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
    animation: none;
  }
}

.categoryHover {
  transition: all 0.4s ease-in-out;
}

.categoryHover:hover {
  transform: scale(1.04, 1.02);
  color: #f3b408;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    background-color: rgba(5, 114, 105, 0.753);
  }

  70% {
    transform: scale(1);
    background-color: rgba(5, 114, 105, 0.747);
  }

  100% {
    transform: scale(0.95);
    background-color: rgba(5, 114, 105, 0.747);
  }
}

.searchgame:focus {
  box-shadow: none !important;
  outline: none !important;
}

.provider_games {
  aspect-ratio: 1;
  border-radius: 3px;
  padding: 0.5rem;
  object-fit: cover;

  clip-path: polygon(
    15% 0%,
    80% 0%,
    100% 0%,
    100% 100%,
    87% 100%,
    20% 100%,
    0% 100%,
    0% 14%
  );
}

.provider_games_clipath {
  clip-path: polygon(
    13% 0%,
    80% 0%,
    100% 0%,
    100% 100%,
    87% 100%,
    20% 100%,
    0% 100%,
    0% 14%
  );
}

.provider_games .provider_games_playBtn {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(100%, 100%);
  transition: all 0.5s ease 0.25s;
  background-color: #000000d8;
  clip-path: polygon(
    15% 0%,
    80% 0%,
    100% 0%,
    100% 100%,
    87% 100%,
    20% 100%,
    0% 100%,
    0% 14%
  );
}

.provider_games:hover .provider_games_playBtn {
  transform: translate(0%, 0%);
  clip-path: polygon(
    15% 0%,
    80% 0%,
    100% 0%,
    100% 100%,
    87% 100%,
    20% 100%,
    0% 100%,
    0% 14%
  );
}

.input_search_game {
  border-bottom: 3px solid white;
}

.userdivtoggle {
  border: 3px solid #f9db7b;
  overflow: hidden;
}

.catDropdown {
  position: absolute;
  left: 50%;
  max-height: 33.5rem;
  overflow: auto;
  z-index: 1000;
  transform: translateX(-50%);
  display: none;
}

.catNav:hover .catDropdown {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 40px;
}

.cattext:hover {
  transform: translateY(-5%);
}

.livechat {
  position: fixed;
  bottom: 0.5rem;
  right: 1rem;
  z-index: 100000;
  cursor: pointer;
}

.lciframe {
  z-index: 10000;
  position: fixed;
  bottom: 6.5rem;
  right: 1rem;
}

.livechatiframe {
  width: 100%;
  height: 500px;
  border: 2px solid black;
  border-radius: 10px;
}

@media (max-width: 600px) {
  .lciframe {
    width: 93%;
  }
  .livechatiframe {
    width: 100%;
    height: 85vh;
  }
}

.livechatiframe::-webkit-scrollbar {
  width: 0px;
}

.glass {
  -webkit-backdrop-filter: blur(8px); /* Safari 9+ */
  backdrop-filter: blur(8px); /* Chrome and Opera */
  box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.08);
  border-radius: 10px;
}

.glassThree {
  -webkit-backdrop-filter: blur(8px); /* Safari 9+ */
  backdrop-filter: blur(8px); /* Chrome and Opera */
  box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
  background-color: rgba(255, 255, 255, 0.08);
}

.glassTwo {
  box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
  background-color: rgba(255, 255, 255, 0.08);
}

.glassFour {
  -webkit-backdrop-filter: blur(8px); /* Safari 9+ */
  backdrop-filter: blur(8px); /* Chrome and Opera */
  box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
  background-color: rgba(0, 0, 0, 0.493);
}

.animation_down {
  animation: moveDown 0.4s linear alternate;
}

.animation_left {
  animation: moveLeft 1s linear alternate;
}

.animation_live {
  animation: moveLeft 0.5s linear alternate;
}

.animation_prov {
  animation: moveProv 0.5s linear alternate;
}

@keyframes moveDown {
  0% {
    transform: translate(-50%, 150%);
    opacity: 0.6;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

@keyframes moveProv {
  0% {
    transform: translate(-50%, 3%);
    opacity: 0.6;
  }
  100% {
    transform: translate(-50%, 0%);
    opacity: 1;
  }
}

@keyframes moveLeft {
  0% {
    transform: translate(0%, 100%);
    opacity: 0.6;
  }
  100% {
    transform: translate(0%, 0%);
    opacity: 1;
  }
}
